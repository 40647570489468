<template>
  <div class="TempRoutePate">
    <div v-for="(data, i) in routeList" :key="`page${i}`">
      <a :href="`#${data.path}`">{{ data.name }}</a>
    </div>
  </div>
</template>

<script>
import Routes from "../config/PageRoutes.vue";

export default {
  data() {
    return {
      routeList: null,
    };
  },
  created() {
    this.routeList = Routes;
  },
};
</script>

<style></style>
